<template>
  <div>
    <v-main>
      <v-form :disabled="isComponent">
        <v-row>
          <v-col cols="12">
            <Buttons
              v-if="!isComponent"
              :textCancel="$t('GENERAL.CANCEL')"
              v-on:cancel="btnCancel"
              :textSave="$t('GENERAL.SAVE')"
              v-on:save="saveData"
              :save_loading="save_loading"
              :btnSaveNew="false"
              :btnDuplicate="$route.params.operation === 'edit'"
              :duplicate_loading="duplicate_loading"
              :textDuplicate="$t('GENERAL.DUPLICATE')"
              v-on:duplicate="duplicateData"
              :btnDelete="$route.params.operation === 'edit'"
              :delete_loading="delete_loading"
              :textDelete="$t('GENERAL.DELETE')"
              v-on:delete="isShowDialogDelete = true"
            />
          </v-col>
        </v-row>
        <v-row gutters="2" class="pt-0 pb-0">
          <v-col cols="12" md="8" class="pt-0 pb-0">
            <!-- Principal Form -->
            <v-card class="mb-2">
              <v-card-text>
                <FormAddon
                  ref="form"
                  :visibility="isReady"
                  @data="savedService"
                  :isOnlyShow="isComponent"
                ></FormAddon>
              </v-card-text>
            </v-card>
            <v-card class="mb-2">
              <v-card-title>{{
                $t("PRODUCTS_ADDONS.PRICING_TEMPLATE")
              }}</v-card-title>
              <v-card-text>
                <PricingTemplate
                  v-if="showPricingTemplate"
                  :key="keyPricingTemplate"
                  :productPricingTemplateId="productPriceTemplateIdListened"
                  :availableOptions="[1, 4]"
                  :defaultSelected="DEFAULT_ONE_TIME_PRICING_TEMPLATE"
                  ref="pricingTemplate"
                  :showMessages="false"
                ></PricingTemplate>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Additional Info -->
          <v-col cols="12" md="4" class="pt-0 pb-0">
            <v-card class="mb-2">
              <v-card-title>{{
                $t("PRODUCTS_ADDONS.VISIBILITY")
              }}</v-card-title>
              <v-card-text>
                <Visibility
                  :value="isReady"
                  :input.sync="isReady"
                  :isPublished="isPublish"
                ></Visibility>
                {{ $t("PRODUCTS_ADDONS.NOTE_ADDON") }}
              </v-card-text>
            </v-card>

            <Translation
              ref="translationComponent"
              :fields="fieldsTranslate"
              model="product"
              skeleton="3"
              :isOnlyShow="isComponent"
            ></Translation>
          </v-col>

          <v-dialog v-model="isShowDialogDelete" max-width="350">
            <v-card>
              <v-card-title class="headline">
                {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
              </v-card-title>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="red darken-1"
                  text
                  @click="isShowDialogDelete = false"
                >
                  {{ $t("MESSAGE.DELETE.MODAL_NO") }}
                </v-btn>

                <v-btn
                  color="primary darken-1"
                  text
                  @click="
                    deleteData();
                    isShowDialogDelete = false;
                  "
                >
                  {{ $t("MESSAGE.DELETE.MODAL_YES") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-form>
    </v-main>
  </div>
</template>

<script>
// Import General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

// Import components
import FormAddon from "@/view/content/components/products_addons/addon/Form";
import Visibility from "@/view/content/components/forms/Visibility";
import Translation from "@/view/content/components/translation/Index";
import PricingTemplate from "@/view/pages/configure/pricing/components/PricingTemplate";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Buttons from "@/view/content/components/Buttons";
export default {
  computed: {
    ...mapGetters(["room", "getFees", "addon", "productPricing"]),
    productPriceTemplateIdListened() {
      return this.productPricingTemplateId;
    },
  },
  props: {
    isComponent: {
      type: Boolean,
      default: false,
    },
    propProductId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FormAddon,
    Visibility,
    Translation,
    PricingTemplate,
    Buttons,
  },
  data() {
    return {
      fieldsTranslate: [
        {
          title: this.$i18n.t("PRODUCTS_ADDONS.NAME"),
          type: "name",
        },
        {
          title: this.$i18n.t("PRODUCTS_ADDONS.DESCRIPTION"),
          type: "description",
        },
      ],
      tab: "",
      isShowDialogDelete: false,
      isVisibleForm: true,

      isEditCourse: false,
      dataGallery: [],
      dataStartDateTemplate: {},
      fees: [],
      isReady: false,
      productPricingTemplateId: 0,
      DEFAULT_ONE_TIME_PRICING_TEMPLATE: 4,
      keyPricingTemplate: 0,
      showPricingTemplate: false,
      isPublish: false,
    };
  },
  created() {
    this.cleanProductPricing();
  },
  async mounted() {
    if (!this.isComponent) {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$i18n.t("PRODUCTS_ADDONS.TITLE"),
        },
        {
          title: `${this.$i18n.t("PRODUCTS_ADDONS.ADDON")}`,
          route: "/products/service",
        },
        {
          title: `${this.$i18n.t(
            "PRODUCTS_ADDONS." + this.$route.params.operation.toUpperCase()
          )}`,
        },
      ]);
      this.loadMountedData();
    } else {
      this.loadMountedData();
    }
  },
  updated() {
    if (this.$route.params.operation === "edit") {
      this.isEditCourse = true;
    } else {
      this.isEditCourse = false;
    }
    if (!this.showPricingTemplate) {
      this.showPricingTemplate = true;
    }
  },
  methods: {
    ...mapActions(["getAddon", "getListFees", "getProductPricing"]),
    ...mapMutations(["cleanProductPricing"]),
    async saveProductPricingTemplate() {
      let productPricingTemplate = false;
      await this.$refs.pricingTemplate.validateAndGetData().then(async data => {
        productPricingTemplate = data;
        if (productPricingTemplate) {
          if (productPricingTemplate.productPricingTemplateId === 0) {
            return await this.$refs.pricingTemplate.store(
              productPricingTemplate
            );
          } else {
            this.productPricingTemplateId =
              productPricingTemplate.productPricingTemplateId;
            return await this.$refs.pricingTemplate.save(
              productPricingTemplate
            );
          }
        } else {
          this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
            variant: "danger",
            solid: true,
          });
          return false;
        }
      });
    },
    async saveData() {
      const result = await this.$refs.form.validateData();
      if (result) {
        await this.saveProductPricingTemplate();
        const resultProduct = await this.$refs.pricingTemplate.validateAndGetData();
        if (resultProduct) {
          this.$refs.form.storeForm(
            this.isReady,
            resultProduct.productPricingTemplateId
          );
        } else {
          return false;
        }
      } else {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
        return false;
      }
    },
    async duplicateData() {
      let productPricingTemplate = false;
      await this.$refs.pricingTemplate.validateAndGetData().then(async data => {
        productPricingTemplate = data;
        if (productPricingTemplate) {
          if (productPricingTemplate.productPricingTemplateId === 0) {
            await this.$refs.pricingTemplate.store(productPricingTemplate);
          } else {
            await this.$refs.pricingTemplate.save(productPricingTemplate);
          }
        }
      });
      this.$refs.form.duplicateForm(
        this.isReady,
        this.productPricingTemplateId
      );
    },
    deleteData() {
      this.$refs.form.deleteRecord();
    },
    btnCancel() {
      this.$router.push("/products/service");
    },
    savedService(data) {
      if (data.product) {
        this.isPublish = data.product.isPublish;
      }
      let dataJson;
      if (data.product) {
        dataJson = [data.name, data.product.description];
      } else {
        dataJson = [data.name, data.description];
      }

      this.$refs.translationComponent.getTranslations(data.productId, dataJson);
    },
    async loadMountedData() {
      this.isVisibleForm = true;
      const productId = this.$route.params.index
        ? this.$route.params.index
        : this.propProductId;
      if (this.$route.params.operation === "edit" || this.isComponent) {
        this.isEditCourse = true;

        await this.getAddon(productId).then(data => {
          this.productPricingTemplateId = data.productPricingTemplateId;
          this.isReady = data.product.isReady;
          this.isPublish = data.product.isPublish;
        });
      } else {
        this.keyPricingTemplate += 1;
        this.isEditCourse = false;
        this.showPricingTemplate = true;
      }
    },
  },
  watch: {
    $route: "loadMountedData",
  },
};
</script>

<style scoped>
.btn-outline-primary {
  border-radius: 0;
}
ul {
  padding: 0px !important;
}
.v-alert__content a {
  color: #d3d3d3;
}
</style>

<template>
  <v-row no-gutters>
    <v-col cols="12" md="12">
      <validation-observer ref="observer">
        <v-form :disabled="isOnlyShow">
          <v-row gutters="2">
            <v-col cols="12" md="12">
              <div class="d-flex justify-content-end">
                <a class="body-2" v-if="help === false" @click="help = true">
                  {{ $t("GENERAL.SHOW_HELP") }}
                </a>
                <a class="body-2" v-else @click="help = false">
                  {{ $t("GENERAL.HIDE_HELP") }}
                </a>
              </div>
            </v-col>
            <v-col cols="12" md="12">
              <v-alert
                v-model="help"
                type="info"
                border="left"
                elevation="2"
                colored-border
              >
                {{ $t("PRODUCTS_ADDONS.SERVICE_INFO") }}
              </v-alert>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.NAME')"
                rules="required"
              >
                <v-text-field
                  v-model="form.name"
                  :label="$t('PRODUCTS_ADDONS.NAME')"
                  outlined
                  :error-messages="errors"
                  dense
                  required
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.TYPE')"
                rules="required"
              >
                <v-select
                  v-model="form.serviceTypeId"
                  :label="$t('PRODUCTS_ADDONS.TYPE')"
                  :items="serviceTypes"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  :error-messages="errors"
                  required
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row gutters="2">
            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.DESCRIPTION')"
                rules="required"
              >
                <v-textarea
                  class="required"
                  v-model="form.description"
                  :label="$t('PRODUCTS_ADDONS.DESCRIPTION')"
                  outlined
                  :error-messages="errors"
                  dense
                  required
                ></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-col>
    <v-dialog
      v-model="isShowDialogForceDelete"
      max-width="500"
      eager
      :key="keyValidateDelete"
    >
      <v-card>
        <v-card-title class="headline">
          <h3>{{ $t("PRODUCTS_ADDONS.NOT_DELETED") }}</h3>
        </v-card-title>

        <v-card-text>
          <v-divider></v-divider>
          <h5 v-if="validatePromotions && validatePromotions.length > 0">
            {{ $t("PRODUCTS_ADDONS.PROMOTION") }}
          </h5>
          <v-list-item
            v-for="(item, index) in validatePromotions"
            :key="`PRO-${index}`"
          >
            <v-list-item-content>
              <v-list-item-title
                ><font-awesome-icon icon="times"></font-awesome-icon> &nbsp;{{
                  item.name
                }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <h5 v-if="validatePackages && validatePackages.length > 0">
            {{ $t("PRODUCTS_ADDONS.PACKAGE") }}
          </h5>

          <v-list-item
            v-for="(item, index) in validatePackages"
            :key="`PAC-${index}`"
          >
            <v-list-item-content>
              <v-list-item-title
                ><font-awesome-icon icon="times"></font-awesome-icon> &nbsp;{{
                  item.name
                }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          {{ $t("PRODUCTS_ADDONS.FORCE_DELETE") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="isShowDialogForceDelete = false"
          >
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn color="blue darken-1" text @click="forceDelete()">
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ForceDelete
      typeProduct="SERVICE"
      :validatePromotions="validatePromotions"
      :validatePackages="validatePackages"
      :productId="this.$route.params.index"
      :redirect="true"
      @close-force-delete="cleanDataForceDelete()"
    ></ForceDelete>
  </v-row>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import ForceDelete from "@/view/pages/productAddon/components/ForceDelete";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ForceDelete,
  },
  props: {
    visibility: Boolean,
    isOnlyShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      help: true,
      form: {
        productId: "",
        name: null,
        serviceTypeId: null,
        description: null,
        isReady: null,
        productPricingTemplateId: null,
      },
      informativeMessageSelected: null,
      informativeMessage: {
        transfer: this.$i18n.t("PRODUCTS_ADDONS.SERVICE.TRANSFER"),
        activities: this.$i18n.t("PRODUCTS_ADDONS.SERVICE.ACTIVITIES"),
      },
      serviceTypes: [
        { id: 1, name: "Transfers" },
        { id: 2, name: "Activities" },
        { id: 7, name: "Exam Fee" },
        { id: 8, name: "Visa" },
        { id: 20, name: "Other" },
      ],
      validatePromotions: [],
      validatePackages: [],
      isShowDialogForceDelete: false,
      keyValidateDelete: 0,
    };
  },
  methods: {
    ...mapActions([
      "getAddon",
      "storeAddon",
      "updateAddon",
      "deleteAddon",
      "forceDeleteService",
    ]),
    storeForm(isReady = false, productPriceTemplateId = 0) {
      this.$refs.observer.validate().then(data => {
        if (data) {
          this.form.isReady = isReady;
          this.form.productPricingTemplateId = productPriceTemplateId;
          if (this.$route.params.operation === "edit") {
            this.form.productId = this.$route.params.index;
            this.updateAddon(this.form).then(response => {
              this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
                title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
                variant: "success",
                solid: true,
              });
              this.$emit("data", response);
            });
          } else {
            this.storeAddon(this.form).then(response => {
              this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
                title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
                variant: "success",
                solid: true,
              });
              this.$emit("data", response);
              this.$router.push({
                name: "product-service-edit",
                params: {
                  operation: "edit",
                  type: "addon",
                  index: response.productId,
                },
              });
              this.form.productId = response.productId;
            });
          }
        } else {
          this.$bvToast.toast("Please verify the fields", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      });
    },
    duplicateForm() {
      this.$refs.observer.validate().then(data => {
        if (data) {
          if (this.$route.params.operation === "edit") {
            this.form.name = this.form.name + " (Copy)";
            delete this.form.productId;
            this.storeAddon(this.form).then(response => {
              this.$bvToast.toast(response.message, {
                title: "Success",
                variant: "success",
                solid: true,
              });
              this.$router.push({
                name: "product-service-edit",
                params: {
                  operation: "edit",
                  index: response.productId,
                },
              });
            });
          }
        } else {
          this.$bvToast.toast("Please verify the fields", {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      });
    },
    deleteRecord() {
      const productId = this.$route.params.index;
      this.deleteAddon(productId).then(data => {
        if (data.success) {
          this.$router.push({ name: "product-service-table" });
          this.$bvToast.toast(this.$t("MESSAGE.DELETE.DESCRIPTION_NEW"), {
            title: this.$t("MESSAGE.DELETE.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        } else {
          this.validatePromotions = data.promotion ? data.promotion : [];
          this.validatePackages = data.package ? data.package : [];
          this.isShowDialogForceDelete =
            this.validatePromotions.length || this.validatePackages.length;
          this.keyValidateDelete += 1;
        }
      });
    },
    cleanDataForceDelete() {
      this.validatePromotions = [];
      this.validatePackages = [];
    },
    async validateData() {
      return await this.$refs.observer.validate();
    },
  },
  computed: {
    ...mapGetters(["addon"]),
  },
  watch: {
    addon(newValue) {
      if (this.$route.params.operation === "edit" || this.isOnlyShow) {
        const dataAddon = JSON.parse(JSON.stringify(newValue));
        this.form.productId = dataAddon.productId;
        this.form.name = dataAddon.product.name;
        this.form.serviceTypeId = dataAddon.serviceTypeId;
        this.form.description = dataAddon.product.description;
        this.$emit("data", this.form);
      }
    },
  },
};
</script>
<style scoped>
.v-list-item {
  min-height: 24px;
}
</style>
